import { jsx as _jsx } from "react/jsx-runtime";
import { CheckedIcon, Icon } from './IconContainer.styled';
import { Checkbox as MuiCheckbox, styled } from '@mui/material';
import { IconContainer } from './IconContainer';
import { forwardRef } from 'react';
import { shouldNotForwardProps } from 'shared/helpers';
const Checkbox = forwardRef((props, ref) => {
    return (_jsx(CheckboxStyled, { disableRipple: true, ...props, icon: _jsx(IconContainer, { children: _jsx(Icon, {}) }), checkedIcon: _jsx(IconContainer, { children: _jsx(CheckedIcon, { color: props.color }) }), indeterminateIcon: _jsx(IconContainer, { children: _jsx(CheckedIcon, { indeterminate: true, color: props.color }) }), slotProps: { input: { ref } } }));
});
const CheckboxStyled = styled(MuiCheckbox, shouldNotForwardProps('heightEqualsInput'))(({ theme, heightEqualsInput }) => ({
    ...(heightEqualsInput && {
        paddingTop: '.25rem',
        paddingBottom: '.25rem',
    }),
}));
export { Checkbox };
