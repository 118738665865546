import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink, useMatches } from 'react-router-dom';
import { BackLink } from './BackLink';
import { BreadcrumbSeparator } from './BreadcrumbSeparator';
import { BreadcrumbsInnerContainer } from './Breadcrumbs.styled';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { t } from 'shared/translations';
// TODO: this component should replace the old one.
const Breadcrumbs = ({ backTo, backLinkLabel, lastItem }) => {
    const matches = useMatches();
    // Get rid of any matches that don't have handle and crumb.
    // Map them into an array of elements, passing the loader data along with url parameters to each crumb.
    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb({ loaderData: match.data, params: matches[0].params }));
    return (_jsxs(BreadcrumbsInnerContainer, { children: [_jsxs(MuiBreadcrumbs, { separator: _jsx(BreadcrumbSeparator, {}), children: [crumbs.map((crumb, index) => (_jsx(crumb.type, { ...crumb.props }, index))), lastItem !== undefined && _jsx(NavLink, { to: "#", children: lastItem }), ";"] }), backTo && _jsx(BackLink, { to: backTo, label: backLinkLabel ?? t('back.label') })] }));
};
export { Breadcrumbs };
