import { jsx as _jsx } from "react/jsx-runtime";
import { DividerStyled } from './Divider.styled';
/**
 * **Global Parts** `Divider` component.
 *
 * @param strong - makes divider color darker
 * @param margin - adds *top* and *bottom* margin using `spacing` argument.
 * @param transparent - makes divider invisible.
 */
const Divider = (props) => {
    return _jsx(DividerStyled, { ...props });
};
export { Divider };
