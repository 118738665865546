import { jsx as _jsx } from "react/jsx-runtime";
import { Chip, styled } from '@mui/material';
export const StatusChipStyled = styled(({ size, ...props }) => _jsx(Chip, { ...props }), {
    shouldForwardProp: (prop) => prop !== 'size',
})(({ theme, size, color, backgroundColor, labelColor }) => {
    let sizeStyles = {};
    if (size === 'large') {
        sizeStyles = {
            padding: '.3125rem .125rem',
            fontSize: theme.typography.caption.fontSize,
        };
    }
    if (size === 'small') {
        sizeStyles = {
            padding: '.1875rem 0',
            fontSize: theme.typography.helper.fontSize,
            borderRadius: `var(--gp-radius-sm)`,
        };
    }
    const colorStyles = backgroundColor && labelColor
        ? {
            backgroundColor,
            color: labelColor,
        }
        : {};
    return {
        borderRadius: `var(--gp-radius-md)`,
        ...sizeStyles,
        ...colorStyles,
    };
});
