import { alpha, backdropClasses, dialogTitleClasses, lighten } from '@mui/material';
const getDialogHeaderFooterBackgroundColor = (theme) => lighten(theme.palette.zinc[800], 0.025);
export const MuiDialog = {
    styleOverrides: {
        root: ({ theme }) => ({
            [`> .${backdropClasses.root}`]: {
                borderRadius: 0,
                backgroundColor: alpha(theme.palette.text.primary, 0.25),
                ...theme.applyStyles('dark', {
                    // catalyst style not working
                    // backgroundColor: alpha(theme.palette.text.primary, 0.5),
                    backgroundColor: `rgba(0,0,0,0.5)`,
                }),
            },
        }),
        paper: ({ theme }) => ({
            borderRadius: `var(--gp-radius-xl)`,
            boxShadow: theme.shadows[12],
        }),
    },
};
export const MuiDialogTitle = {
    defaultProps: {
        component: 'div',
    },
    styleOverrides: {
        root: ({ theme }) => [
            {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                padding: theme.spacing(3, 6),
                minHeight: '3.75rem',
                backgroundColor: theme.palette.background.light,
                // Overridden by DialogTitleCaption and DialogTitleEntityId components
                // '.MuiTypography-h4': {
                // 	lineHeight: '2.25rem',
                // },
                // '.MuiTypography-h3': {
                // 	lineHeight: '2.1rem',
                // },
            },
            theme.applyStyles('dark', {
                // backgroundColor: theme.palette.zinc[800],
                // backgroundColor: lighten(theme.palette.zinc[800], 0.025),
                backgroundColor: getDialogHeaderFooterBackgroundColor(theme),
            }),
        ],
    },
};
export const MuiDialogContent = {
    styleOverrides: {
        root: ({ theme }) => [
            {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(4, 6),
                [`.${dialogTitleClasses.root}+&`]: {
                    paddingTop: theme.spacing(4),
                },
            },
        ],
    },
};
export const MuiDialogContentText = {
    styleOverrides: {
        root: ({ theme }) => ({
            color: theme.palette.text.muted,
            lineHeight: '1.5rem',
            marginTop: theme.spacing(2),
        }),
    },
};
export const MuiDialogActions = {
    styleOverrides: {
        root: ({ theme }) => [
            {
                padding: theme.spacing(3, 6),
                backgroundColor: theme.palette.background.light,
                '> :not(:first-of-type)': {
                    marginLeft: theme.spacing(3),
                },
            },
            theme.applyStyles('dark', {
                backgroundColor: getDialogHeaderFooterBackgroundColor(theme),
            }),
        ],
    },
};
