import { svgIconClasses, tabClasses, tabsClasses } from '@mui/material';
export const MuiTabs = {
    styleOverrides: {
        root: {
            paddingTop: '2px',
            marginBottom: '-1px',
            [`&:not(.${tabsClasses.vertical})`]: {
                [`.${tabClasses.root}:not(:first-of-type)`]: {
                    marginLeft: '2rem',
                },
            },
        },
        indicator: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.primary.dark,
        }),
        vertical: ({ ownerState, theme }) => ({
            [`.${tabClasses.root}`]: {
                '&:hover': {
                    borderColor: 'transparent',
                },
            },
        }),
    },
};
export const MuiTab = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            minHeight: '48px',
            height: '48px',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            paddingRight: '.25rem',
            paddingLeft: '.25rem',
            color: theme.palette.text.muted,
            borderBottomStyle: 'solid',
            borderBottomWidth: '2px',
            borderBottomColor: 'transparent',
            [`.${svgIconClasses.root}`]: {
                width: '.9375rem',
                height: '.9375rem',
            },
            '&.Mui-selected': {
                color: theme.palette.primary.dark,
                ...theme.applyStyles('dark', {
                    color: theme.palette.primary.light,
                }),
            },
            ':not(.Mui-selected)': {
                [`.${svgIconClasses.root}`]: {
                    color: theme.palette.text.muted,
                },
                ':hover': {
                    color: theme.palette.text.medium,
                },
            },
            '&:hover': {
                borderWidth: '2px',
                borderColor: theme.palette.neutral.light,
                ...theme.applyStyles('dark', {
                    borderColor: theme.palette.zinc[600],
                }),
            },
            '&.Mui-focusVisible': {
                outlineColor: ownerState
                    ? ownerState.color === 'primary'
                        ? theme.palette.primary.dark
                        : ownerState.color === 'secondary'
                            ? theme.palette.secondary.dark
                            : ownerState.color === 'success'
                                ? theme.palette.success.dark
                                : ownerState.color === 'error'
                                    ? theme.palette.error.dark
                                    : ownerState.color === 'info'
                                        ? theme.palette.info.dark
                                        : ownerState.color === 'warning'
                                            ? theme.palette.warning.dark
                                            : theme.palette.primary.dark
                    : theme.palette.primary.dark,
                outlineOffset: '-6px',
                // BorderRadius is used for :focus-visible outline only.
                borderRadius: `var(--gp-radius-xl)`,
                // Removes 2px hover underline, since it has border radius on hover.
                borderColor: 'transparent',
            },
        }),
    },
};
export const MuiTabPanel = {
    styleOverrides: {
        root: {
            padding: 0,
        },
    },
};
