import { Paper, Popper, TextField, autocompleteClasses, formControlLabelClasses, outlinedInputClasses, styled } from '@mui/material';
import { Checkbox } from 'core/ui';
export const PopperStyled = styled(Popper)({
    width: '100%',
});
export const FilterTextField = styled(TextField)(({ theme }) => ({
    paddingInline: theme.spacing(2),
    [`.${outlinedInputClasses.root}`]: {
        padding: '0 .5rem !important',
    },
}));
export const SelectAllCheckboxContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: theme.spacing(2, 0),
    borderRadius: `var(--gp-radius-lg)`,
    ':hover': {
        backgroundColor: theme.palette.background.default,
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.zinc[700],
        }),
    },
    [`.${formControlLabelClasses.root}`]: {
        width: '100%',
        padding: theme.spacing(2, 3),
        marginLeft: 0,
    },
}));
export const SelectAllCheckbox = styled(Checkbox)({
    padding: 0,
    marginRight: '.5rem',
});
export const PaperStyled = styled(Paper)(({ theme }) => ({
    boxShadow: theme.shadows[11],
    borderRadius: `var(--gp-radius-xl)`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.zinc[800],
    }),
}));
export const PaperHead = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    backgroundColor: 'transparent',
}));
// TODO: to delete all unnecessary comments
export const AutocompletePopperStyled = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    borderRadius: 0,
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        //fontSize: 13,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: `var(--gp-radius-lg)`,
        borderBottomRightRadius: `var(--gp-radius-lg)`,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        padding: theme.spacing(1, 0),
        [`.${autocompleteClasses.option}`]: {
            position: 'relative',
            paddingRight: theme.spacing(9),
            marginRight: theme.spacing(2),
            gap: theme.spacing(2),
        },
    },
    // [`& .${autocompleteClasses.listbox}`]: {
    // 	backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    // 	padding: 0,
    // 	[`& .${autocompleteClasses.option}`]: {
    // 		minHeight: 'auto',
    // 		alignItems: 'flex-start',
    // 		padding: 8,
    // 		borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
    // 		'&[aria-selected="true"]': {
    // 			backgroundColor: 'transparent',
    // 		},
    // 		[`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
    // 			backgroundColor: theme.palette.action.hover,
    // 		},
    // 	},
    // },
    // [`&.${autocompleteClasses.popperDisablePortal}`]: {
    // 	position: 'relative',
    // },
}));
