import { jsx as _jsx } from "react/jsx-runtime";
import { OutlinedInput } from '@mui/material';
import { forwardRef } from 'react';
/**
 * **Global Parts** `TextFiled` component.
 *
 * Please, use `inputRef` instead of just `ref` to handle focus!
 *
 * Uses MUI's `OutlinedInput` under the hood and handles console error when 'undefined' is passed as value.
 */
const TextField = forwardRef(({ value, ...props }, ref) => {
    // `inputRef={inputRef}` is included in props! Use `inputRef` for focus native input element.
    return _jsx(OutlinedInput, { ...props, ref: ref, label: "", value: value === null || value === undefined ? '' : value });
});
/**
 * **Global Parts** `TextFiled` **uncontrolled** component.
 *
 * Please, use `inputRef` instead of just `ref` to handle focus!
 *
 * Uses MUI's `OutlinedInput` under the hood and handles console error when 'undefined' is passed as value.
 */
const TextFieldUncontrolled = forwardRef(({ value, ...props }, ref) => {
    // Should be `ref={ref}`, since `inputRef={inputRef}` is used to handle focus!
    return _jsx(OutlinedInput, { ...props, ref: ref, label: "", value: value === null ? '' : value });
});
export { TextFieldUncontrolled, TextField };
