import { TableHead as MuiTableHead, styled, tableCellClasses, typographyClasses } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
export const TableHeadStyled = styled(MuiTableHead, shouldNotForwardProps('secondary'))(({ theme, secondary }) => [
    {
        ...(secondary && {
            [`.${tableCellClasses.root}`]: {
                borderTopStyle: 'solid',
                borderTopColor: theme.palette.dividerTranslucent,
                borderTopWidth: '1px',
                backgroundColor: theme.palette.background.light,
                color: theme.palette.text.secondary,
                [`.${typographyClasses.root}`]: {
                    color: 'inherit',
                },
            },
        }),
    },
    theme.applyStyles('dark', {
        ...(secondary && {
            [`.${tableCellClasses.root}`]: {
                borderTopColor: theme.palette.divider,
                backgroundColor: theme.palette.zinc[800],
                color: theme.palette.text.light,
            },
        }),
    }),
]);
