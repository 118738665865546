import { lighten, styled } from '@mui/material';
const DrawerFooter = styled('div')(({ theme }) => [
    {
        display: 'flex',
        justifyContent: 'right',
        margin: 0,
        padding: theme.spacing(3, 6),
        backgroundColor: theme.palette.background.light,
        '> button:not(:first-of-type)': {
            marginLeft: theme.spacing(3),
        },
    },
    theme.applyStyles('dark', {
        backgroundColor: lighten(theme.palette.background.light, 0.05),
    }),
]);
export { DrawerFooter };
