import * as components from './components';
import { typography } from './typography';
const themeOptions = {
    //spacing: 4, // 8 is default Mui recommended.
    //spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
    // spacing: (factor: number) => `${0.5 * factor}rem`,
    spacing: (factor) => `${0.25 * factor}rem`,
    //spacing: [0, 4, 8, 16, 32, 64],
    // palette: {
    // 	// mycompany: {
    // 	// 	primary: '#003366',
    // 	// },
    // },
    shape: {
        // 6 is now working for buttons and inputs. Paper radius is overridden inside MuiPaper styles.
        // borderRadius: 6, // '0.375rem' - tailwind, // 4 - MUI default
        borderRadius: 8, // '.5rem' // --radius-lg Catalyst
    },
    // shadows: shadows,
    typography: typography,
    components: components,
};
export { themeOptions };
/*
  --radius-xs: .125rem;
  --radius-sm: .25rem;
  --radius-md: .375rem; // borderRadius: 6
  --radius-lg: .5rem; // borderRadius: 8
  --radius-xl: .75rem;
  --radius-2xl: 1rem;
  --radius-3xl: 1.5rem;
  --radius-4xl: 2rem;
  --shadow-2xs: 0 1px #0000000d;
  --shadow-xs: 0 1px 2px 0 #0000000d;
  --shadow-sm: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --shadow-md: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --shadow-lg: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --shadow-xl: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --shadow-2xl: 0 25px 50px -12px #00000040;
  --inset-shadow-2xs: inset 0 1px #0000000d;
  --inset-shadow-xs: inset 0 1px 1px #0000000d;
  --inset-shadow-sm: inset 0 2px 4px #0000000d;
  --drop-shadow-xs: 0 1px 1px #0000000d;
  --drop-shadow-sm: 0 1px 2px #00000026;
  --drop-shadow-md: 0 3px 3px #0000001f;
  --drop-shadow-lg: 0 4px 4px #00000026;
  --drop-shadow-xl: 0 9px 7px #0000001a;
  --drop-shadow-2xl: 0 25px 25px #00000026;
  --ease-in: cubic-bezier(.4,0,1,1);
  --ease-out: cubic-bezier(0,0,.2,1);
  --ease-in-out: cubic-bezier(.4,0,.2,1);
*/
