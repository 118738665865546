import { IconButton, styled } from '@mui/material';
export const IconButtonStyled = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'customColor' })(({ theme, customColor }) => [
    {
        color: customColor ? getHexColorByDefaultColor(customColor, theme) : theme.palette.primary.dark,
        '&.Mui-disabled': {
            color: theme.palette.primary.dark,
            opacity: 0.4,
        },
    },
    theme.applyStyles('dark', {
        ...(!customColor && {
            color: theme.palette.primary.main,
        }),
    }),
]);
/**
 * `primary.main`, `secondary.dark`, `text.disabled`, etc.. are expected.
 *
 * @param customColor
 * @returns
 */
function getHexColorByDefaultColor(customColor, theme) {
    const result = theme.palette.text.primary;
    if (!customColor)
        return result;
    const fixedColor = customColor === 'textDisabled'
        ? 'text.disabled'
        : customColor === 'textPrimary'
            ? 'text.primary'
            : customColor === 'textSecondary'
                ? 'text.secondary'
                : customColor === 'textMedium'
                    ? 'text.medium'
                    : customColor === 'textLight'
                        ? 'text.light'
                        : customColor === 'textMuted'
                            ? 'text.muted'
                            : customColor === 'textTranslucent'
                                ? 'text.translucent'
                                : // "primary" | "secondary" | "success" | "error" | "info" | "warning"
                                    !customColor.includes('.')
                                        ? `${customColor}.main`
                                        : customColor;
    const colorParts = fixedColor.split('.');
    // @ts-ignore
    if (colorParts.length === 1)
        return theme.palette[colorParts[0]];
    // @ts-ignore
    else if (colorParts.length === 2)
        return theme.palette[colorParts[0]][colorParts[1]];
    return result;
}
