import { alpha, styled } from '@mui/material';
import { getButtonAndInputBoxShadow } from 'core/theme';
const Icon = styled('span')(({ theme }) => [
    {
        // border: '1px solid rgb(209 213 219)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: `var(--gp-radius-sm)`,
        borderColor: 'rgb(209 213 219)',
        color: 'rgb(79 70 229)',
        width: '1rem',
        height: '1rem',
        //boxShadow: theme.palette.mode === 'dark' ? '0 0 0 1px rgb(16 22 26 / 40%)' : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        //backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#fff',
        backgroundOrigin: 'border-box',
        // backgroundImage:
        // 	theme.palette.mode === 'dark'
        // 		? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        // 		: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            //outline: '2px auto rgba(19,124,189,.6)',
            outline: '2px solid transparent',
            outlineOffset: '2px',
            // --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
            // --tw-ring-offset-width: 2px;
            // --tw-ring-offset-color: #fff;
            // --tw-ring-color: #2563eb;
            // --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            // --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            // box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
            boxShadow: `rgb(255, 255, 255) 0px 0px 0px 2px, rgb(99, 102, 241) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px`,
        },
        'input:hover ~ &': {
        //backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        //'input:disabled ~ &': {
        '.Mui-disabled &': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : theme.palette.background.light,
        },
    },
    theme.applyStyles('dark', {
        boxShadow: getButtonAndInputBoxShadow(theme),
        border: 'none',
        backgroundColor: alpha(theme.palette.zinc[200], 0.15),
    }),
]);
const CheckedIcon = styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'indeterminate',
})(({ theme, indeterminate, color }) => ({
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.dark,
    backgroundImage: indeterminate
        ? `url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggc3Ryb2tlPScjZmZmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTQgOGg4Jy8+PC9zdmc+)`
        : `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")`,
    // '&:before': {
    // 	display: 'block',
    // 	width: 16,
    // 	height: 16,
    // 	// backgroundImage:
    // 	// 	"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
    // 	// 	" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
    // 	// 	"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    // 	backgroundImage: `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")`,
    // 	content: '""',
    // },
    'input:hover ~ &': {
        backgroundColor: theme.palette.primary.dark, // main - if we need hover change
    },
}));
export { Icon, CheckedIcon };
