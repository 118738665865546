import { iconButtonClasses, styled } from '@mui/material';
import { SnackbarContent } from 'notistack';
export const SnackbarContentStyled = styled(SnackbarContent)(({ theme }) => [
    {
        borderRadius: theme.shape.borderRadius + 2,
        width: '27rem', // 24rem was not enough for 'order import rule update failed' message.
        maxWidth: '27rem',
        minWidth: '27rem',
        overflow: 'hidden',
        backgroundColor: theme.palette.common.white,
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px',
    },
    theme.applyStyles('dark', {
        backgroundColor: theme.palette.zinc[700],
        boxShadow: theme.shadows[11],
    }),
]);
export const RootContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'alignCenter' })(({ theme, alignCenter }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: alignCenter ? 'center' : 'flex-start',
    flex: 1,
    padding: '1rem',
}));
export const IconContainer = styled('div')({
    display: 'flex',
    flexShrink: 0,
    marginRight: '0.75rem',
});
export const TextContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '.125rem',
    paddingBottom: '.125rem',
    flex: '1 1 0%',
    width: '0px',
});
export const CloseButtonContainer = styled('div')(({ theme }) => [
    {
        display: 'flex',
        flexShrink: 0,
        marginLeft: theme.spacing(4),
    },
    theme.applyStyles('dark', {
        [`.${iconButtonClasses.root}`]: {
            ':hover': {
                color: theme.palette.text.muted,
            },
        },
    }),
]);
