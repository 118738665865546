import { autocompleteClasses, checkboxClasses } from '@mui/material';
export const MuiAutocomplete = {
    styleOverrides: {
        root: {},
        popper: ({ theme }) => ({
            borderRadius: 'var(--gp-radius-xl)',
        }),
        paper: ({ theme }) => [
            {
                boxShadow: theme.shadows[11],
                borderRadius: 'var(--gp-radius-xl)',
            },
            theme.applyStyles('dark', {
                backgroundColor: theme.palette.zinc[800],
            }),
        ],
        listbox: ({ theme }) => ({
            // TODO: all styled duplicated from MuiMenuItem. Mb make common styles?
            minWidth: '16rem',
            padding: theme.spacing(1),
            [`.${autocompleteClasses.option}`]: {
                paddingBlock: theme.spacing(1.5),
                paddingInline: theme.spacing(3),
            },
        }),
        option: ({ theme }) => [
            {
                '&.Mui-focused': {
                // TODO: to delete later
                //backgroundColor: `${theme.palette.primary.dark} !important`,
                //color: `${theme.palette.common.white} !important`,
                // backgroundColor: `${theme.palette.primary.lightest50} !important`,
                },
                '&[aria-selected="true"]': {
                    fontWeight: theme.typography.fontWeightMedium,
                    backgroundColor: 'transparent',
                },
                '&[aria-selected="true"]:not(.Mui-focused)': {
                    backgroundColor: 'transparent',
                },
                [`.${checkboxClasses.root}`]: {
                    padding: 0,
                },
                // TODO: all styled duplicated from MuiMenuItem. Mb make common styles?
                color: theme.palette.text.medium,
                borderRadius: `var(--gp-radius-lg)`,
            },
            theme.applyStyles('dark', {
                '&[aria-selected="true"]:not(.Mui-focused)': {
                    backgroundColor: 'transparent',
                },
                '&[aria-selected="true"].Mui-focused': {
                    backgroundColor: theme.palette.info.main,
                },
                ':hover': {
                    backgroundColor: theme.palette.info.main,
                    color: theme.palette.text.primary,
                },
            }),
        ],
        inputRoot: ({ theme }) => ({
            '.MuiAutocomplete-input': {
                width: '100%',
            },
            '.MuiIconButton-root': {
                color: theme.palette.zinc[400],
            },
        }),
    },
};
