import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
const getPaletteMode = () => {
    const storageValue = localStorage.getItem('paletteMode');
    if (storageValue === 'light' || storageValue === 'dark')
        return storageValue;
    return 'light';
};
const initialState = {
    sidebarCollapsed: (localStorage.getItem('sidebarCollapsed') || false) === 'true',
    paletteMode: getPaletteMode(),
};
const useLayoutStore = create()(immer((set) => ({
    ...initialState,
    toggleSidebarWidth: () => {
        set((state) => {
            const newValue = !state.sidebarCollapsed;
            state.sidebarCollapsed = newValue;
            localStorage.setItem('sidebarCollapsed', newValue.toString());
        });
    },
    togglePaletteMode: () => {
        set((state) => {
            const newValue = state.paletteMode === 'light' ? 'dark' : 'light';
            state.paletteMode = newValue;
            localStorage.setItem('paletteMode', newValue.toString());
        });
    },
})));
export { useLayoutStore };
