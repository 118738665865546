import { Box, FormHelperText, Link, styled } from '@mui/material';
import { Alert } from '../Alert';
export const DropBoxContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'disabled' })(({ theme, disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100%',
    gap: theme.spacing(2),
    opacity: disabled ? 0.5 : 1,
}));
export const DropBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    position: 'relative',
    padding: '2.5rem 1.5rem',
    borderRadius: `var(--gp-radius-lg)`,
    borderStyle: 'dashed',
    borderWidth: '1px',
    borderColor: theme.palette.zinc[300],
}));
// TODO: to delete later (not used)
export const HelperText = styled(FormHelperText)(({ theme }) => ({
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    lineHeight: '50px',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.light,
}));
export const NarrowedAlert = styled(Alert)(({ theme }) => ({
    margin: theme.spacing(0, 2),
}));
export const LinkBlock = styled('div')({
    display: 'inline-flex',
    gap: '.2rem',
    marginTop: '16px',
});
export const UploadFileLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'disabled' })(({ theme, disabled }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    color: disabled ? theme.palette.text.muted : undefined,
}));
export const inputTypeFileStyles = {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%', // full width and full height allow drag'n'drop.
    height: '100%',
};
