export const MuiPaper = {
    defaultProps: {
        elevation: 2,
        // Another way to avoid `--Paper-overlay` of MUI default behavior for dark theme (as fixed below with `backgroundImage: 'none'`.
        // style: { '--Paper-overlay': 'none' },
    },
    styleOverrides: {
        root: ({ theme }) => [
            {
                borderRadius: `var(--gp-radius-lg)`,
                // https://mui.com/material-ui/react-paper/#elevation
                // The aforementioned dark mode behavior can lead to confusion when overriding the Paper component, because changing the background-color property won't affect the lighter shading. To override it, you must either use a new background value, or customize the values for both background-color and background-image.
                backgroundImage: 'none',
            },
            theme.applyStyles('dark', {
                backgroundColor: theme.palette.background.light,
            }),
        ],
    },
};
