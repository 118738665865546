import { stockBaseApi } from 'shared/modules/stock/business';
export const stockApi = stockBaseApi.enhanceEndpoints({ addTagTypes: ['Articles'] }).injectEndpoints({
    endpoints: (builder) => ({
        addStockItem: builder.mutation({
            query: (stockItem) => ({
                method: 'POST',
                url: 'stock/createStockItem',
                body: stockItem,
            }),
            invalidatesTags: (result, error, stockItem) => ['Stock', 'StockList', 'Articles'],
        }),
        updateStockBalanceMargin: builder.mutation({
            query: ({ itemsUpdate }) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: itemsUpdate.map(({ stockBalanceId, marginPercent }) => ({ stockBalanceId, marginPercent })),
            }),
            // Invalidate should not be used to avoid page rerender, update is performed manually with onQueryStarted function.
            // invalidatesTags: (result, error, { itemsUpdate: items }) => items.map((item) => ({ type: 'Stock', id: item.stockItemId })),
            onQueryStarted: async (request, { dispatch, queryFulfilled, getState }) => {
                const { stockId, grouped, itemsUpdate } = request;
                try {
                    await queryFulfilled;
                    const patchResult = dispatch(stockApi.util.updateQueryData('getStockBalance', { stockId, grouped }, (draft) => {
                        const patch = draft.map((stockCell) => ({
                            ...stockCell,
                            stockItems: stockCell.stockItems.map((stockItem) => {
                                const itemUpdate = itemsUpdate.find((itemUpdate) => itemUpdate.stockItemId === stockItem.stockItemId && itemUpdate.stockBalanceId === stockItem.stockBalanceId);
                                return itemUpdate ? { ...stockItem, marginPercent: itemUpdate.marginPercent } : stockItem;
                            }),
                        }));
                        Object.assign(draft, patch);
                    }));
                }
                catch {
                    // Next line to be used with optimistic update.
                    // patchResult.undo();
                    // Alternatively, on failure we can invalidate the corresponding cache tags to trigger a re-fetch:
                    dispatch(stockApi.util.invalidateTags(['Stock']));
                }
            },
        }),
        updateStockBalanceCost: builder.mutation({
            query: ({ itemsUpdate }) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: itemsUpdate.map(({ stockBalanceId, primeCost }) => ({ stockBalanceId, primeCost })),
            }),
            // Invalidate should not be used to avoid page rerender, update is performed manually with onQueryStarted function.
            // invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
            onQueryStarted: async (request, { dispatch, queryFulfilled, getState }) => {
                const { stockId, grouped, itemsUpdate } = request;
                try {
                    await queryFulfilled;
                    const patchResult = dispatch(stockApi.util.updateQueryData('getStockBalance', { stockId, grouped }, (draft) => {
                        const patch = draft.map((stockCell) => ({
                            ...stockCell,
                            stockItems: stockCell.stockItems.map((stockItem) => {
                                const itemUpdate = itemsUpdate.find((itemUpdate) => itemUpdate.stockItemId === stockItem.stockItemId && itemUpdate.stockBalanceId === stockItem.stockBalanceId);
                                return itemUpdate ? { ...stockItem, primeCost: itemUpdate.primeCost } : stockItem;
                            }),
                        }));
                        Object.assign(draft, patch);
                    }));
                }
                catch {
                    // Next line to be used with optimistic update.
                    // patchResult.undo();
                    // Alternatively, on failure we can invalidate the corresponding cache tags to trigger a re-fetch:
                    dispatch(stockApi.util.invalidateTags(['Stock']));
                }
            },
        }),
        // updateStockItemQuantity: builder.mutation<void, { stockItemId: number; count: number }[]>({
        updateStockItemQuantity: builder.mutation({
            query: ({ itemsUpdate }) => ({
                method: 'POST',
                url: 'stock/updateStockItem',
                body: itemsUpdate.map(({ stockItemId, count }) => ({ stockItemId, count })),
            }),
            // Invalidate should not be used to avoid page rerender, update is performed manually with onQueryStarted function.
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            // invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
            onQueryStarted: async (request, { dispatch, queryFulfilled, getState }) => {
                const { stockId, grouped, itemsUpdate } = request;
                try {
                    await queryFulfilled;
                    const patchResult = dispatch(stockApi.util.updateQueryData('getStockBalance', { stockId, grouped }, (draft) => {
                        const patch = draft.map((stockCell) => ({
                            ...stockCell,
                            stockItems: stockCell.stockItems.map((stockItem) => {
                                const itemUpdate = itemsUpdate.find(
                                // (itemUpdate) => itemUpdate.stockItemId === stockItem.stockItemId && itemUpdate.stockBalanceId === stockItem.stockBalanceId
                                (itemUpdate) => itemUpdate.stockItemId === stockItem.stockItemId);
                                return itemUpdate ? { ...stockItem, count: itemUpdate.count } : stockItem;
                            }),
                        }));
                        Object.assign(draft, patch);
                    }));
                }
                catch {
                    // Next line to be used with optimistic update.
                    // patchResult.undo();
                    // Alternatively, on failure we can invalidate the corresponding cache tags to trigger a re-fetch:
                    dispatch(stockApi.util.invalidateTags(['Stock']));
                }
            },
        }),
        // relocateStockItem: builder.mutation<void, { stockItemId: number; quantity: number; cellName: string }[]>({
        relocateStockItem: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stock/relocate',
                body,
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }, { type: 'StockList' }],
        }),
        /** Activates or deactivates stock balances */
        activateStockBalance: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, isActive }) => ({ stockBalanceId, isActive })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        deleteStockBalance: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stock/deleteStockBalance',
                body,
            }),
            // TODO: check `{type: 'Articles'}` is probably not working (on new article add).
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }, { type: 'Articles' }],
        }),
        uploadPricesFile: builder.mutation({
            query: ({ file, stockId }) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('stockId', JSON.stringify(stockId));
                return {
                    method: 'POST',
                    url: 'stockDocument/updatePrice',
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: [{ type: 'Stock' }],
        }),
    }),
    overrideExisting: false,
});
export const { useGetStockListQuery, useGetStockInfoQuery, useGetStockBalanceQuery, useExportStockToExcelMutation, useAddStockItemMutation, useGetStockItemCostHistoryQuery, useUpdateStockBalanceMarginMutation, useUpdateStockBalanceCostMutation, useUpdateStockItemQuantityMutation, useRelocateStockItemMutation, useGetStockDictionaryQuery, useActivateStockBalanceMutation, useDeleteStockBalanceMutation, useUploadPricesFileMutation, } = stockApi;
