import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Typography } from '@mui/material';
import { LoadingWrapper } from './Spinner.styled';
import { t } from 'shared/translations';
/**
 * To handle *loading* or *fetching* states in dialogs {@link fullHeight} property should be used.
 *
 * Always full width.
 *
 * @param caption
 * @param fullHeight
 * @returns
 */
const Spinner = ({ fullHeight, caption }) => {
    return (_jsxs(LoadingWrapper, { fullHeight: fullHeight, children: [_jsx(CircularProgress, { size: 22 }), _jsx(Typography, { component: "span", variant: "body1", color: "text.medium", children: caption ?? t('preload.loading') })] }));
};
export { Spinner };
