import { jsx as _jsx } from "react/jsx-runtime";
import { SnackbarContent as NotistackSnackbarContent } from 'notistack';
import { Typography, iconButtonClasses, styled } from '@mui/material';
export const SnackbarContent = styled(NotistackSnackbarContent, { shouldForwardProp: (prop) => prop !== 'variant' })(({ theme, variant }) => ({
    borderRadius: `var(--gp-radius-lg)`,
    // width: '24rem',
    maxWidth: '54rem',
    minWidth: '30rem',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px',
}));
export const RootContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'alignCenter' && prop !== 'variant' })(({ theme: { palette }, alignCenter, variant }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: alignCenter ? 'center' : 'flex-start',
    flex: 1,
    padding: '1rem',
    // TODO: to delete later if we still do not need colors in background
    // backgroundColor:
    // 	variant === 'receiptSubmissionSuccess'
    // 		? palette.success.main
    // 		: variant === 'receiptSubmissionError'
    // 		? palette.error.main
    // 		: palette.common.white,
    backgroundColor: variant === 'receiptSubmissionSuccess'
        ? palette.success.background
        : variant === 'receiptBlockSuccess'
            ? palette.warning.background
            : variant === 'receiptSubmissionError'
                ? palette.error.background
                : palette.common.white,
}));
export const IconContainer = styled('div')({
    display: 'flex',
    flexShrink: 0,
    marginLeft: '.5rem',
    marginRight: '1.5rem',
});
export const TextContentContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '.125rem',
    paddingBottom: '.125rem',
    flex: '1 1 0%',
    width: 'fit-content',
    gap: '1rem',
}));
export const CloseButtonContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'variant' })(({ theme, variant }) => ({
    display: 'flex',
    flexShrink: 0,
    marginLeft: '2rem',
    [`.${iconButtonClasses.root}`]: {
        // TODO: to delete later if we still do not need colors in background
        // color:
        // 	variant === 'receiptSubmissionSuccess'
        // 		? palette.success.lighter
        // 		: variant === 'receiptSubmissionError'
        // 		? palette.error.lighter
        // 		: palette.background.light,
        '> svg': {
            width: '2rem',
            height: '2rem',
        },
        ':not(:hover)': {
        // TODO: to delete later if we still do not need colors in background
        // color: palette.common.white,
        },
    },
}));
export const SubmissionSnackTypography = (props) => _jsx(Typography, { ...props, component: "span", variant: "h2", noWrap: true });
