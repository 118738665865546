import { styled } from '@mui/material';
export const DetailsBlockContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});
export const DetailsBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    columnGap: theme.spacing(8),
}));
export const DetailsDescription = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});
export const DetailsDescriptionHead = styled('p')(({ theme }) => ({
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
    marginTop: 0,
    marginBottom: theme.spacing(1),
}));
export const DetailsDescriptionContent = styled('p')(({ theme }) => ({
    color: theme.palette.text.muted,
}));
export const DetailsContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
});
export const DetailsEmpty = DetailsDescription;
