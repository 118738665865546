import { SignalrConnectionHub, signalrHubConnector } from 'warehouse/signalR';
import { downloadFile, providesList } from 'shared/helpers/api';
import { getErrorFromException, getQueryFulfilledError } from 'core/utils/errorHandling';
import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { enqueueSnackbar } from 'notistack';
import { formatDateForApiRequest } from 'core/utils/datetime';
import { isValid } from 'date-fns';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const shipmentApi = createApi({
    reducerPath: 'api/shipment',
    tagTypes: ['ShipmentList', 'ShipmentStickers'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getShipments: builder.query({
            query: (request) => {
                return {
                    method: 'POST',
                    url: 'shipment',
                    body: convertToApiModel(request),
                };
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (ex) {
                    dispatch(showApiErrorSnackbar({
                        header: t('get.shipments.query.failed'),
                        error: getQueryFulfilledError(ex),
                    }));
                }
            },
            transformResponse: (response) => response.map((item) => ({ ...item, createDate: new Date(item.createDate) })),
            providesTags: (result) => providesList(result, 'ShipmentList', 'outDocumentId'),
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Shipment);
                try {
                    await cacheDataLoaded;
                    const documentGeneratedEventListener = (message) => {
                        updateCachedData((draft) => {
                            // TODO: to delete
                            // console.log('getPackingItems, onCacheEntryAdded in stickerPackedEventListener, draft:', current(draft));
                            // console.log('getPackingItems, onCacheEntryAdded in stickerPackedEventListener, message:', message);
                            draft.unshift(message);
                        });
                    };
                    addEventListener('DocumentGenerated', documentGeneratedEventListener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved;
            },
        }),
        getShipmentStickers: builder.query({
            query: (outDocumentId) => ({
                method: 'GET',
                url: `shipment/stickers/${outDocumentId}`,
            }),
            transformResponse: (response) => ({
                availableStickers: response.availableStickers.map((sticker) => ({
                    ...sticker,
                    // TODO: uncomment if createDate or invoiceDate fields is in use
                    // createDate: new Date(sticker.createDate),
                    // invoiceDate: new Date(sticker.invoiceDate),
                })),
                shipmentStickers: response.shipmentStickers.map((sticker) => ({
                    ...sticker,
                    // TODO: uncomment if createDate or invoiceDate fields is in use
                    // createDate: new Date(sticker.createDate),
                    // invoiceDate: new Date(sticker.invoiceDate),
                })),
            }),
            providesTags: ['ShipmentStickers'],
        }),
        updateShipmentDocument: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'shipment/stickers/save',
                body,
            }),
            invalidatesTags: (result, error, { outDocumentId }) => [{ type: 'ShipmentList', outDocumentId }],
        }),
        resendDocument: builder.mutation({
            query: ({ outDocumentId }) => ({
                method: 'POST',
                url: 'picking/repush',
                body: [outDocumentId],
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    enqueueSnackbar({ variant: 'success', header: t('document.has.been.successfully.resent') });
                }
                catch (error) {
                    // `onError` side-effect
                    // dispatch(messageCreated('Error fetching post!'))
                    enqueueSnackbar({
                        variant: 'error',
                        header: t('document.resend.failed'),
                        body: getErrorFromException(error),
                        persist: true,
                    });
                }
            },
        }),
        exportDocumentToExcel: builder.query({
            query: ({ outDocumentId }) => ({
                method: 'GET',
                url: `shipment/documents/${outDocumentId}`,
                cache: 'no-cache',
            }),
            transformResponse: (response) => response.forEach((el) => downloadFile(el)),
            // TODO: to delete (in this case the approach doesn't work)
            // transformResponse: (response: string[]) => {
            // 	response.forEach((el) => {
            // 		downloadFile(el);
            // 	});
            // },
        }),
    }),
});
/**
 * Shipment endpoint accepts dates in ISO format like '2024-06-17T10:43:47.934Z'.
 * @param {ShipmentsRequest} range - required
 * @returns
 */
const convertToApiModel = (range) => ({
    // TODO: to delete later (not working for some reason (-3 hour))
    // dateFrom: range.dateFrom.toISOString(),
    // dateTo: range.dateTo.toISOString(),
    dateFrom: isValid(range.dateFrom) ? formatDateForApiRequest(range.dateFrom) : '',
    dateTo: isValid(range.dateTo) ? formatDateForApiRequest(range.dateTo) : '',
});
export const { useGetShipmentsQuery, useGetShipmentStickersQuery, useLazyExportDocumentToExcelQuery, useResendDocumentMutation, useUpdateShipmentDocumentMutation, } = shipmentApi;
