import { alpha, dividerClasses, listItemIconClasses, menuClasses, popoverClasses } from '@mui/material';
import { menuPaperTopOffset } from 'core/constants';
// TODO: to delete all redundant commented code. after redesign
export const MuiMenu = {
    styleOverrides: {
        paper: ({ ownerState, theme }) => ({
            // We have to use !important because MuiMenu-paper got overridden by MuiPopover-paper by default.
            // boxShadow:
            // 	'#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important',
            /* TODO: TO RETURN or to delete if moved to `shadows`.
            // We have to use !important because MuiMenu-paper got overridden by MuiPopover-paper by default.
            boxShadow:
                // `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(9, 9, 11, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important`,
                `${theme.palette.common.white} 0px 0px 0px 0px, ${theme.palette.divider} 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important`,
            */
            outlineColor: 'transparent',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            // This doesn't work without `!important` because overridden with inline styles further.
            transform: `translateY(${menuPaperTopOffset}) !important`,
        }),
        list: ({ theme }) => ({
            minWidth: '16rem',
            padding: theme.spacing(1),
            // TODO: check if variant='menu' is already added and use it instead of following styles
            [`.${dividerClasses.root}`]: {
                marginBlock: theme.spacing(1),
                marginInline: theme.spacing(3),
                backgroundColor: alpha(theme.palette.text.primary, 0.05),
                ...theme.applyStyles('dark', {
                    backgroundColor: alpha(theme.palette.common.white, 0.1),
                }),
            },
        }),
    },
};
export const MuiMenuItem = {
    styleOverrides: {
        root: ({ theme }) => [
            {
                color: theme.palette.text.medium,
                paddingBlock: theme.spacing(1.5),
                paddingInline: theme.spacing(3),
                borderRadius: `var(--gp-radius-lg)`,
                '&[hidden]': {
                    display: 'none',
                },
                [`.${listItemIconClasses.root}`]: {
                    minWidth: theme.spacing(7),
                    color: theme.palette.text.muted,
                },
                ':hover': {
                    backgroundColor: theme.palette.background.default,
                },
                [`.${popoverClasses.paper}.${menuClasses.paper} &`]: {
                    ':focus-visible': {
                        outline: 'none',
                    },
                },
            },
            theme.applyStyles('dark', {
                ':hover': {
                    backgroundColor: theme.palette.info.main,
                    color: theme.palette.text.primary,
                },
            }),
        ],
    },
};
