import { jsx as _jsx } from "react/jsx-runtime";
import { PeriodDateRange, getPeriodDateRangeValues, periodDateRangeDeserializer, } from 'core/ui';
import { convertToDateRange, getDatesFromStoredValue, prepareForStorage } from './helpers';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCoreSessionStorage } from 'core/hooks';
const debouncedDateRangeValueDelay = 900;
// TODO: check for updates
// While usehooks-ts's one isn't working.
const useDebouncedCallback = (callbackFunction, waitMs) => {
    const timeout = useRef(null);
    return useCallback((...args) => {
        const later = () => {
            // @ts-ignore
            clearTimeout(timeout.current);
            // @ts-ignore
            callbackFunction(...args);
        };
        // @ts-ignore
        clearTimeout(timeout.current);
        timeout.current = setTimeout(later, waitMs);
    }, [callbackFunction, waitMs]);
};
/**
 * Enhances {@link PeriodDateRange} component.
 *
 * Addons: stores value in *sessionStorage*, adds debounce on value change.
 *
 * **Important**: should be used with explicit generic type.
 *
 * @param {string} sessionStorageKey - required, key under which the value will be stored.
 * @param {boolean} [disabled] - optional
 * @param {string} [buttonClassName] - optional, className to be applied to all toggle buttons.
 * @param {(value: DateRangeFromTo) => void} onChange
 * @returns
 */
const StoredPeriodDateRange = ({ sessionStorageKey, defaultPeriod = 'month', disabled, buttonClassName, onChange, ...dateRangePickerOwnProps }) => {
    const initialValue = useMemo(() => ({
        period: defaultPeriod,
        // // last two weeks for `custom` range
        // periodDateFrom: customPeriodDefaultDateFrom,
        // periodDateTo: customPeriodDefaultDateTo,
        ...getPeriodDateRangeValues(defaultPeriod),
    }), [defaultPeriod]);
    const [storageValue, setStorageValue] = useCoreSessionStorage(sessionStorageKey, initialValue, {
        deserializer: periodDateRangeDeserializer(initialValue),
    });
    // TODO: return after usehooks-ts will have fixed not working hook
    // const onChangeDebounced = useDebounceCallback(onChange, debouncedDateRangeValueDelay);
    const onChangeDebounced = useDebouncedCallback(onChange, debouncedDateRangeValueDelay);
    useEffect(() => {
        // Force value update to have stored or initial value to pass to a query that handles onChange event (since value is null without that).
        onChange(getDatesFromStoredValue(storageValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(PeriodDateRange, { value: storageValue, disabled: disabled, invalid: false, buttonClassName: buttonClassName, onChange: (value) => {
            setStorageValue(prepareForStorage(value));
            onChangeDebounced(convertToDateRange(value));
        }, ...dateRangePickerOwnProps }));
};
export { StoredPeriodDateRange };
