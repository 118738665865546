import { Box, Container, Paper as MuiPaper } from '@mui/material';
import { styled } from '@mui/material/styles';
const RootBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
}));
const Header = styled(Box)(({ theme }) => [
    {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '150px',
        width: '100%',
        backgroundColor: '#2D2D2D',
        '& > img': {
            height: '100px',
        },
    },
    theme.applyStyles('dark', {
        backgroundColor: theme.palette.background.default,
    }),
]);
const PaperContainer = styled(Container)(({ theme }) => [
    {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        paddingBottom: '120px',
        backgroundColor: theme.palette.zinc[200],
    },
    theme.applyStyles('dark', {
        backgroundColor: theme.palette.background.default,
    }),
]);
const Paper = styled(MuiPaper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(14),
    maxWidth: '448px',
}));
export { RootBox, Header, Paper, PaperContainer };
