import { Backdrop, styled } from '@mui/material';
export const BackdropStyled = styled(Backdrop)(({ theme }) => [
    {
        backgroundColor: theme.palette.common.white,
        borderRadius: 0,
    },
    theme.applyStyles('dark', {
        backgroundColor: theme.palette.background.default,
    }),
]);
