import { Link, List as MuiList, ListItem as MuiListItem, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText, styled, } from '@mui/material';
export const List = styled(MuiList)(({ theme }) => ({
    bgcolor: 'background.paper',
    border: `1px solid ${theme.palette.zinc[200]}`,
    borderRadius: `var(--gp-radius-md)`,
    padding: 0,
}));
export const ListItem = styled(MuiListItem)({
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    paddingRight: '100px',
    maxHeight: 'unset',
});
export const ListItemIcon = styled(MuiListItemIcon)({
    minWidth: 0,
    marginTop: 0,
    maxHeight: 'unset',
});
export const ListItemText = styled(MuiListItemText)({
    margin: '0 0 0 .5rem',
    '> .MuiListItemText-primary': { display: 'flex', minWidth: '0px' },
});
export const DeleteLink = styled(Link)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body1.fontSize,
}));
export const sxDivider = ({ palette }) => ({ borderColor: palette.background.default });
export const sxFileNameText = ({ typography }) => ({ fontWeight: typography.fontWeightMedium });
export const sxFileSize = ({ palette }) => ({ color: palette.text.muted, padding: '0 0 0 .5rem' });
