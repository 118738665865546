import { svgIconClasses } from '@mui/material';
export const MuiAlert = {
    styleOverrides: {
        root: ({ theme }) => ({
            fontSize: theme.typography.body1.fontSize,
            borderRadius: `var(--gp-radius-md)`,
            padding: theme.spacing(4),
        }),
        icon: ({ theme }) => ({
            padding: '0',
            marginRight: theme.spacing(3),
            [`.${svgIconClasses.root}`]: {
                height: '1.125rem',
                width: '1.125rem',
            },
        }),
        message: {
            padding: 0,
            lineHeight: '1.25rem',
        },
    },
};
export const MuiAlertTitle = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            color: 'inherit',
        }),
    },
};
