import { alpha } from '@mui/material';
export const MuiDivider = {
    styleOverrides: {
        root: {
            variants: [
                {
                    props: { variant: 'menu' },
                    style: ({ theme }) => ({
                        // TODO: check if MUI fixed the bug. Not possible to use styles without `!important` modificator, since the style is overrided with
                        // Mui-root styled for some reason
                        // marginTop: theme.spacing(1),
                        // marginBottom: theme.spacing(1),
                        marginBlock: `${theme.spacing(1)} !important`,
                        marginInline: theme.spacing(3),
                        backgroundColor: alpha(theme.palette.text.primary, 0.05),
                        ...theme.applyStyles('dark', {
                            backgroundColor: alpha(theme.palette.common.white, 0.1),
                        }),
                    }),
                },
            ],
        },
    },
};
