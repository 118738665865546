import { showApiErrorSnackbar, showApiSuccessSnackbar } from 'shared/helpers/thunk';
import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'shared/translations';
export const customerApi = createApi({
    reducerPath: 'api/customers',
    tagTypes: ['Customers', 'DeliveryAddresses', 'Legals', 'Emails', 'NotifySchedule', 'ShippingSettings', 'PriceListMarkup', 'OrderImportRules'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getCustomers: builder.query({
            query: ({ limit = 100, offset, search }) => ({
                url: 'Customer',
                params: { limit, offset, search },
                method: 'GET',
            }),
            // 		export const mapUsers = (users: UserListItem[]) =>
            // users.map((u) => ({
            // 	...u,
            // 	modifiedLocalTime: parseDateTime(u.modifiedLocalTime)
            // }));
            transformResponse: (response) => response.map((customer) => ({ ...customer, createDate: new Date(customer.createDate) })),
            //providesTags: (result) => ['Customers'],
            //providesTags: ['Customers'],
            // providesTags: (result) =>
            // 	// is result available?
            // 	result
            // 		? // successful query
            // 		  [...result.map(({ customerId }) => ({ type: 'Customers', customerId } as const))]
            // 		: // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            // 		  ['Customers'],
        }),
        getCustomer: builder.query({
            query: (id) => ({
                method: 'GET',
                url: `Customer/${id}`,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.customer.request.failed'), error }));
                }
            },
            transformResponse: (response) => ({
                ...response,
                createDate: new Date(response.createDate),
                changeDate: new Date(response.changeDate),
                birthday: response.birthday ? new Date(response.birthday) : null,
                contractDate: new Date(response.contractDate),
            }),
            providesTags: (result, error, id) => [{ type: 'Customers', id }],
        }),
        addCustomer: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `Customer`,
                body,
            }),
        }),
        updateCustomer: builder.mutation({
            query: ({ customerId, ...body }) => ({
                method: 'PUT',
                url: `Customer/${customerId}`,
                body,
            }),
            // useInfinityScroll refresh is used for update customer row.
            // invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', id: customerId }],
        }),
        getTransactions: builder.query({
            query: ({ customerId, limit = 100, offset }) => ({
                method: 'GET',
                // userfullinfo/transactions?userId=472&page=0&rowscount=50
                url: 'userfullinfo/transactions',
                params: { userid: customerId, page: offset / limit, rowscount: limit },
            }),
            transformResponse: (response) => ({
                ...response,
                payments: response.payments.map((tran) => ({ ...tran, date: new Date(tran.date) })),
            }),
        }),
        rechargeBalance: builder.mutation({
            query: ({ customerId, ...body }) => ({
                method: 'POST',
                url: `userfullinfo/addpayment`,
                params: { userid: customerId },
                body: body,
            }),
            //invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', customerId }],
        }),
        // setCustomerActive: builder.mutation<void, { customerId: number; statusId: number }>({
        setCustomerActive: builder.mutation({
            //query: ({ customerId, ...body }) => ({
            //query: (body) => ({
            query: ({ customerId, isActive }) => ({
                method: 'PUT',
                url: `Customer/${customerId}/active/${isActive}`,
                //body,
            }),
            invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', customerId }],
        }),
        getDeliveryAddresses: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/delivery-addresses`,
            }),
            providesTags: ['DeliveryAddresses'],
            // TODO: to delete this transformResponse when address is ok.
            transformResponse: (response) => {
                return response.map((r) => {
                    return { ...r, deliveryAddress: r.city };
                });
            },
        }),
        editDeliveryAddress: builder.mutation({
            //query: (body) => ({
            query: ({ deliveryAddress, customerId }) => ({
                //url: `Customer/${body.customerId}/delivery-addresses${body.deliveryAddressId ? '/' + body.deliveryAddressId : ''}`,
                method: deliveryAddress.deliveryAddressId ? 'PUT' : 'POST',
                url: `Customer/${customerId}/delivery-addresses${deliveryAddress.deliveryAddressId ? '/' + deliveryAddress.deliveryAddressId : ''}`,
                // TODO: to return this after address becomes one-line.
                //body,
                // JUST A MOCK to be deleted
                //body: { ...body, city: body.deliveryAddress, street: 'STREET', house: 'HOUSE', office: 'OFFICE', structure: 'STRUCTURE', building: 'BUILDING' },
                body: {
                    ...deliveryAddress,
                    city: deliveryAddress.deliveryAddress,
                    street: 'STREET',
                    house: 'HOUSE',
                    office: 'OFFICE',
                    structure: 'STRUCTURE',
                    building: 'BUILDING',
                },
            }),
            onQueryStarted: async ({ deliveryAddress }, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({
                        header: deliveryAddress.deliveryAddressId
                            ? t('edit.customer.delivery.address.request.failed')
                            : t('add.customer.delivery.address.request.failed'),
                        error,
                    }));
                }
            },
            invalidatesTags: ['DeliveryAddresses'],
        }),
        getLegals: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/legals`,
            }),
            providesTags: ['Legals'],
        }),
        // TODO: The only one 'Legal/' endpoint. If other is found then move to separate api.
        getOwnLegalLookup: builder.query({
            query: () => ({
                method: 'GET',
                url: `Legal/OwnLegalDictionary`,
            }),
            transformResponse: (value) => {
                const result = [];
                for (const key in value) {
                    result.push({ ownLegalId: +key, name: value[key] });
                }
                return result;
            },
            //providesTags: ['Legals'],
        }),
        editLegal: builder.mutation({
            query: ({ legal, customerId }) => ({
                method: legal.legalId ? 'PUT' : 'POST',
                url: `Customer/${customerId}/legals${legal.legalId ? '/' + legal.legalId : ''}`,
                body: legal,
            }),
            onQueryStarted: async ({ legal }, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: legal.legalId ? t('edit.customer.legal.request.failed') : t('add.customer.legal.request.failed'), error }));
                }
            },
            invalidatesTags: ['Legals'],
        }),
        getShippingSettings: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/pickingSettings`,
            }),
            providesTags: ['ShippingSettings'],
        }),
        updateShippingSettings: builder.mutation({
            query: ({ shippingSettings, customerId }) => ({
                method: 'PUT',
                url: `Customer/${customerId}/pickingSettings`,
                body: shippingSettings,
            }),
            invalidatesTags: ['ShippingSettings'],
        }),
        getEmails: builder.query({
            query: (customerId) => ({
                method: 'GET',
                url: `Customer/${customerId}/emails`,
            }),
            providesTags: ['Emails'],
        }),
        editEmail: builder.mutation({
            query: ({ customerId, ...email }) => email.emailId
                ? {
                    method: 'PUT',
                    url: `Customer/${customerId}/emails/${email.emailId}`,
                    body: email,
                }
                : {
                    method: 'POST',
                    url: `Customer/${customerId}/emails`,
                    body: email,
                },
            invalidatesTags: ['Emails'],
        }),
        deleteEmail: builder.mutation({
            query: ({ customerId, emailId }) => ({
                method: 'DELETE',
                url: `Customer/${customerId}/emails/${emailId}`,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    dispatch(showApiSuccessSnackbar(t('customer.email.has.been.successfully.deleted')));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('delete.customer.email.request.failed'), error }));
                }
            },
            invalidatesTags: ['Emails'],
        }),
        getNotifySchedule: builder.query({
            query: ({ customerId }) => ({
                method: 'GET',
                url: `Customer/${customerId}/notifySchedule`,
            }),
            transformResponse: (result) => result.map((item) => (item.length > 5 ? item.slice(0, 5) : item)),
            providesTags: ['NotifySchedule'],
        }),
        editNotifySchedule: builder.mutation({
            query: ({ customerId, scheduleItems }) => ({
                method: 'POST',
                url: `Customer/${customerId}/notifySchedule`,
                body: scheduleItems,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    dispatch(showApiSuccessSnackbar(t('notification.schedule.has.been.successfully.updated')));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('update.customer.notify.schedule.request.failed'), error }));
                }
            },
            invalidatesTags: ['NotifySchedule'],
        }),
        // getPriceListMarkup: builder.query<IPriceListMarkup[], { customerId: number | string; search: string }>({
        getPriceListMarkup: builder.query({
            query: ({ customerId, search }) => ({
                method: 'GET',
                //url: `Customer/${customerId}/priceListMarkup`,
                url: `markup/pricelist`,
                params: { userid: customerId },
            }),
            providesTags: ['PriceListMarkup'],
        }),
        setPriceListMarkup: builder.mutation({
            query: ({ customerId, priceListId, markup }) => ({
                method: 'POST',
                url: `markup/pricelist`,
                params: { userid: customerId, pricelistid: priceListId },
                body: markup || {},
            }),
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceListMarkup', priceListId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    dispatch(showApiSuccessSnackbar(t('personal.markup.on.the.price.list.has.been.successfully.updated')));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('personal.markup.on.the.price.list.update.request.failed'), error }));
                }
            },
        }),
        // TODO: probably needed only for customers page. Btw is it still used anywhere?
        setConnectedToWebApp: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `Customer/ConnectedToWebAppState/${body.priceListId}`,
                body,
            }),
            // TODO: uncomment and check if it is working!
            //invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceLists', priceListId }],
        }),
        setConnectedToCustomer: builder.mutation({
            query: ({ customerId, priceListId, active }) => ({
                method: 'POST',
                //url: `Customer/ConnectedToCustomerState/${body.priceListId}`,
                url: `markup/pricelistavailability`,
                params: { userId: customerId, pricelistId: priceListId },
                body: active,
            }),
            // TODO: uncomment and check if it is working!
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceListMarkup', priceListId }],
        }),
        getOrderImportRules: builder.query({
            query: ({ customerId }) => ({
                method: 'GET',
                //url: `Customer/${customerId}/getOrderImportRules`,
                url: `externalOrder/list`,
                params: { userId: customerId },
            }),
            transformResponse: (response) => response.map((item) => ({ ...item, createDate: new Date(item.createDate) })).sort((a, b) => b.createDate.getTime() - a.createDate.getTime()),
            providesTags: ['OrderImportRules'],
        }),
        getOrderImportRule: builder.query({
            query: ({ ruleId }) => ({
                method: 'GET',
                url: `externalOrder`,
                params: { settingId: ruleId },
            }),
            // forceRefetch: (params) => {
            // 	console.log('params.currentArg: ', params.currentArg);
            // 	console.log('params.previousArg: ', params.previousArg);
            // 	if (params.currentArg !== params.previousArg) return true;
            // 	return false;
            // },
            providesTags: ['OrderImportRules'],
        }),
        updateOrderImportRule: builder.mutation({
            query: ({ rule, customerId }) => ({
                method: 'POST',
                //url: `Customer/${customerId}/editOrdersFromFileRule${rule.externalOrderSettingsId ? '/' + rule.externalOrderSettingsId : ''}`,
                url: `externalorder`,
                //method: rule.externalOrderSettingsId ? 'PUT' : 'POST',
                body: { ...rule, userId: customerId },
            }),
            // TODO: uncomment and check if it is working!
            invalidatesTags: ['OrderImportRules'],
            //invalidatesTags: (result, error, { rule }) => [{ type: 'OrderImportRules', rule.externalOrderSettingsId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    dispatch(showApiSuccessSnackbar(t('order.import.rule.updated.successfully')));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('order.import.rule.update.request.failed'), error }));
                }
            },
        }),
        deleteOrderImportRule: builder.mutation({
            query: ({ ruleId }) => ({
                method: 'DELETE',
                url: `externalOrder`,
                params: { settingId: ruleId },
            }),
            // TODO: uncomment and check if it is working!
            //invalidatesTags: ['OrderImportRules'],
            invalidatesTags: (result, error, { ruleId }) => [{ type: 'OrderImportRules', ruleId }],
        }),
        setOrderImportRuleActive: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `externalOrder/availability`,
                body,
            }),
            invalidatesTags: (result, error, { settingsId }) => [{ type: 'OrderImportRules', settingsId }],
        }),
        // TODO: use proper method after it is created on backend.
        getExportPriceLists: builder.query({
            query: () => ({
                method: 'GET',
                url: 'exportpricelist',
            }),
        }),
    }),
});
export const { useGetCustomersQuery, useGetCustomerQuery, useLazyGetCustomerQuery, useUpdateCustomerMutation, useGetTransactionsQuery, useRechargeBalanceMutation, useSetCustomerActiveMutation, useAddCustomerMutation, useGetDeliveryAddressesQuery, useEditDeliveryAddressMutation, useGetLegalsQuery, useEditLegalMutation, useGetOwnLegalLookupQuery, useGetShippingSettingsQuery, useUpdateShippingSettingsMutation, useGetEmailsQuery, useEditEmailMutation, useDeleteEmailMutation, useGetNotifyScheduleQuery, useEditNotifyScheduleMutation, useGetPriceListMarkupQuery, useSetPriceListMarkupMutation, useSetConnectedToWebAppMutation, useSetConnectedToCustomerMutation, useGetOrderImportRulesQuery, useGetOrderImportRuleQuery, useUpdateOrderImportRuleMutation, useDeleteOrderImportRuleMutation, useSetOrderImportRuleActiveMutation, useGetExportPriceListsQuery, } = customerApi;
