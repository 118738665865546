import { Paper, styled } from '@mui/material';
// TODO: check if it is used.
export const PagePaper = styled(Paper, { shouldForwardProp: (prop) => prop !== 'tight' })(({ theme, tight }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: tight ? '1.75rem' : '2rem',
    gap: 2,
    boxShadow: theme.shadows[10],
}));
