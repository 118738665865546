import { Chip as MuiChip, chipClasses, styled } from '@mui/material';
export const ChipWithShapeStyled = styled(MuiChip, { shouldForwardProp: (prop) => prop !== 'shape' })(({ theme, shape }) => ({
    ...(shape === 'rect' && {
        [`&.${chipClasses.sizeSmall}`]: {
            borderRadius: `var(--gp-radius-md)`,
        },
        [`&.${chipClasses.sizeMedium}`]: {
            borderRadius: `var(--gp-radius-md)`,
        },
    }),
}));
