import { jsx as _jsx } from "react/jsx-runtime";
import { Select as MuiSelect } from '@mui/material';
// TODO: refactor
/**
 * **Global Parts** Select.
 *
 * Uses *MuiSelect* under the hood and handles console error when 'undefined' is passed as *value*.
 *
 * @param strict makes warning enabled again on `undefined`.
 */
const Select = ({ strict = false, value, ...props }) => {
    // Style overrides are necessary inside the component, but not global, since MuiMenu is used not only in Select.
    return (_jsx(MuiSelect, { ...props, 
        // @ts-expect-error
        // value={strict ? value : props.multiple ? value || [] : value || ''}
        // In case `||` is used instead of `??` nullish value (`0`, for example) is count as null and it is not working (important for enums).
        // TODO: CHECK!!! if it works with new ?? sign
        value: strict ? value : props.multiple ? value ?? [] : value ?? '' }));
};
export { Select };
