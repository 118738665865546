import { styled, tableContainerClasses } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
/**
 * Wraps a {@link Table} inside a fixed block with borders and scroller.
 *
 * Main goal of this Wrapper is to hide scroller's keen edges that go beyond the boundaries of a {@link TableContainer}.
 *
 * Should be used inside {@link Dialog} as a parent of {@link TableContainer}.
 */
export const DialogTableContainerWrapper = styled('div', shouldNotForwardProps('height'))(({ theme, height }) => [
    {
        overflow: 'hidden',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.neutral.light,
        borderRadius: `var(--gp-radius-lg)`,
        [`.${tableContainerClasses.root}`]: {
            height: height,
            maxHeight: height,
        },
    },
    theme.applyStyles('dark', {
        borderColor: theme.palette.zinc[600],
    }),
]);
