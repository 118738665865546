import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
const api = createApi({
    reducerPath: 'api/settings/location',
    tagTypes: ['Locations'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getLocations: builder.query({
            query: () => ({
                method: 'GET',
                url: 'cellSettings/storageSector',
            }),
            // SAME: providesTags: (result) => providesList(result, 'Locations', 'locationId'),
            providesTags: (result) => result
                ? [...result.map(({ locationId }) => ({ type: 'Locations', id: locationId })), { type: 'Locations', id: 'LIST' }, 'Locations']
                : [{ type: 'Locations', id: 'LIST' }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.locations.request.failed'), error }));
                }
            },
        }),
        getLocation: builder.query({
            query: (id) => ({
                method: 'GET',
                url: `cellSettings/storageSector/${id}`,
            }),
            // providesTags: ['Location'],
            providesTags: (result, error, id) => [{ type: 'Locations', id }],
        }),
        editLocation: builder.mutation({
            query: (body) => ({
                method: 'DELETE',
                url: `cellSettings/deleteStorage`,
                body,
            }),
            invalidatesTags: (result, error, { locationId }) => [{ type: 'Locations', id: locationId }],
        }),
        deleteLocation: builder.mutation({
            query: (locationId) => ({
                method: 'DELETE',
                url: `cellSettings/deleteStorage`,
                body: { locationId },
            }),
            // invalidatesTags: ['Location'],
            // TODO: CHECK if it is working correct and only a single row is updated! If not, just change id to locationId everywhere, according to
            // https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
            invalidatesTags: (result, error, locationId) => [{ type: 'Locations', id: locationId }],
        }),
    }),
});
export { api as locationApi };
export const { useGetLocationsQuery, useGetLocationQuery, useEditLocationMutation, useDeleteLocationMutation } = api;
