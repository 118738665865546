import { alpha, toggleButtonClasses } from '@mui/material';
export const MuiToggleButtonGroup = {
    defaultProps: {
        size: 'large',
    },
    styleOverrides: {
        root: {
            borderRadius: `var(--gp-radius-md)`,
            boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
        },
        lastButton: {
            borderLeft: 0,
        },
        middleButton: {
            borderLeft: 0,
        },
    },
};
export const MuiToggleButton = {
    styleOverrides: {
        root: ({ ownerState, theme }) => [
            {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.common.white,
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '1.25rem',
                boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${theme.palette.zinc[200]} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`,
                borderWidth: 0,
                whiteSpace: 'nowrap',
                ':hover': {
                    backgroundColor: theme.palette.background.light,
                },
                [`&.${toggleButtonClasses.primary}&.${toggleButtonClasses.selected}`]: {
                    boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${theme.palette.indigo[200]} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`,
                    backgroundColor: theme.palette.primary.lightest50,
                },
                // TODO: not working for some reason. Try new MUI version later.
                // focusVisible: {
                // 	outlineColor: ownerState?.color ?? theme.palette.primary.dark,
                // 	outlineOffset: '2px',
                // 	outlineWidth: '2px',
                // 	outlineStyle: 'solid',
                // },
                '&.Mui-focusVisible': {
                    outlineColor: ownerState
                        ? ownerState.color === 'primary'
                            ? theme.palette.primary.dark
                            : ownerState.color === 'secondary'
                                ? theme.palette.secondary.dark
                                : ownerState.color === 'success'
                                    ? theme.palette.success.dark
                                    : ownerState.color === 'error'
                                        ? theme.palette.error.dark
                                        : ownerState.color === 'info'
                                            ? theme.palette.info.dark
                                            : ownerState.color === 'warning'
                                                ? theme.palette.warning.dark
                                                : theme.palette.primary.dark
                        : theme.palette.primary.dark,
                    outlineOffset: '2px',
                    outlineWidth: '2px',
                    outlineStyle: 'solid',
                    zIndex: theme.zIndex.fab,
                },
            },
            theme.applyStyles('dark', {
                // color: theme.palette.text.primary,
                // backgroundColor: theme.palette.background.light,
                backgroundColor: 'transparent',
                fontWeight: theme.typography.fontWeightRegular,
                boxShadow: 'none',
                borderWidth: 1,
                borderStyled: 'solid',
                // borderColor: 'color-mix(in oklab,var(--gp-palette-text-primary) 10%,transparent)',
                borderColor: alpha(theme.palette.text.primary, 0.1),
                ':hover': {
                    borderWidth: 1,
                    // backgroundColor: alpha(theme.palette.text.primary, 0.25),
                    backgroundColor: alpha(theme.palette.text.primary, 0.1),
                    boxShadow: 'none',
                },
                [`&.${toggleButtonClasses.primary}&.${toggleButtonClasses.selected}`]: {
                    // boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${theme.palette.indigo[200]} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`,
                    boxShadow: 'none',
                    backgroundColor: alpha(theme.palette.primary.main, 0.15),
                    color: theme.palette.primary.light,
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                    ':hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.25),
                    },
                },
            }),
        ],
        sizeLarge: {
            padding: '.5rem .75rem',
        },
        sizeMedium: {
            padding: '.3125rem .5rem',
        },
        sizeSmall: {
            fontSize: '13px',
            padding: '.125rem .5rem',
        },
    },
};
