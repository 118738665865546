import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { MenuItem, Select, Skeleton } from 'core/ui';
import { StockType } from 'shared/modules/stock/models';
import { Typography } from '@mui/material';
import { t } from 'shared/translations';
import { useGetStockDictionaryQuery } from 'shared/modules/stock/business';
const StockSelect = ({ stockType, fullWidth, ...controllerProps }) => {
    const { field: { disabled, ref, ...field }, fieldState: { invalid, error }, } = useController(controllerProps);
    const { data: stockDictionary, isError, isLoading, } = useGetStockDictionaryQuery({
        stockType: !stockType || stockType === 'stock' ? StockType.Stock : stockType === 'crossdocking' ? StockType.Crossdocking : StockType.Transfer,
    });
    if (isLoading)
        return (_jsx(Skeleton, { children: _jsx(Select, { fullWidth: true }) }));
    if (!stockDictionary?.length)
        return _jsx(Typography, { color: "error.main", children: t('stock.list.is.empty.in.select.component.warning') });
    return (_jsx(Select, { ...field, fullWidth: fullWidth, disabled: disabled || isError, children: stockDictionary.map((item) => (_jsx(MenuItem, { value: item.stockId, children: item.stockName }, item.stockId))) }));
};
export { StockSelect };
