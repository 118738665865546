export const MuiPopover = {
    styleOverrides: {
        paper: ({ ownerState, theme }) => [
            {
                borderRadius: 'var(--gp-radius-xl)',
                boxShadow: theme.shadows[11],
            },
            theme.applyStyles('dark', {
                backgroundColor: theme.palette.zinc[800],
            }),
        ],
    },
};
