export const MuiSelect = {
    styleOverrides: {
        select: {
            height: '1.5rem',
        },
        icon: ({ ownerState, theme }) => ({
            color: theme.palette.text.disabled,
        }),
    },
};
