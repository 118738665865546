import { Divider, styled } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
export const DividerStyled = styled(Divider, shouldNotForwardProps('strong', 'margin', 'transparent'))(({ theme, strong, margin, transparent }) => [
    {
        borderColor: theme.palette.dividerTranslucent,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        ...(strong && {
            borderColor: theme.palette.divider,
        }),
        ...(margin && {
            marginTop: theme.spacing(margin),
            marginBottom: theme.spacing(margin),
        }),
        ...(transparent && {
            borderColor: 'transparent',
        }),
    },
]);
