import { TableBody as MuiTableBody, styled, tableCellClasses } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
export const TableBodyStyled = styled(MuiTableBody, shouldNotForwardProps('dense', 'relative'))(({ theme, dense, relative }) => ({
    ...(dense && {
        [`.${tableCellClasses.root}`]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    }),
    ...(relative && {
        position: 'relative',
    }),
}));
