import { lighten, styled, typographyClasses } from '@mui/material';
const DrawerHeader = styled('div')(({ theme }) => [
    {
        display: 'flex',
        alignItems: 'flex-end',
        backgroundColor: theme.palette.background.light,
        margin: 0,
        padding: theme.spacing(3, 6),
        minHeight: '3.75rem',
        [`> .${typographyClasses.root}`]: {
            lineHeight: '2.1rem',
        },
    },
    theme.applyStyles('dark', {
        backgroundColor: lighten(theme.palette.background.light, 0.05),
    }),
]);
export { DrawerHeader };
